<template>
    <div class="w-100">
        <el-form :model="changePasswordModel.changePasswordForm"
                 :rules="changePasswordModel.rules"
                 ref="changePasswordForm"
                 class="w-100">
            <!-- Old Password -->
            <el-form-item prop="oldPassword"
                          class="d-flex justify-content-center">
                <el-input style="width: 25rem"
                          placeholder="Type your current password..."
                          show-password
                          prefix-icon="fas fa-lock"
                          type="password"
                          v-model="changePasswordModel.changePasswordForm.oldPassword"
                          autocomplete="off"></el-input>
            </el-form-item>

            <!-- Password -->
            <el-form-item prop="newPassword"
                          class="d-flex justify-content-center">
                <el-input style="width: 25rem"
                          placeholder="Type your new password..."
                          show-password
                          prefix-icon="fas fa-lock"
                          type="password"
                          v-model="changePasswordModel.changePasswordForm.newPassword"
                          autocomplete="off"></el-input>
            </el-form-item>

            <!-- Retype Password -->
            <el-form-item prop="confirmPassword"
                          class="d-flex justify-content-center">
                <el-input style="width: 25rem"
                          placeholder="Type your new password again..."
                          show-password
                          prefix-icon="fas fa-lock"
                          type="password"
                          v-model="changePasswordModel.changePasswordForm.confirmPassword"
                          autocomplete="off"></el-input>
            </el-form-item>

            <!-- Submit -->
            <el-form-item class="d-flex justify-content-center mt-4 mb-0">
                <el-button style="width: 25rem"
                           @click="submitChangePassword('changePasswordForm')"
                           type="primary"
                           round>SUBMIT</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import commonAct from "@/commonActionHandle.js";
    import baseComponent from "@/scripts/baseComponent";
    import authApi from "@/api/common/auth";
    export default {
        extends: baseComponent,
        data() {
            return {
                isLoading: false,
                changePasswordModel: {
                    changePasswordForm: {
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    },
                    rules: {
                        oldPassword: [
                            {
                                required: true,
                                message: "Old password must not be blank",
                                trigger: "blur",
                            },
                        ],
                        newPassword: [
                            {
                                required: true,
                                message: "New password must not be blank",
                                trigger: "blur",
                            },
                        ],

                        confirmPassword: [
                            {
                                required: true,
                                message: "Retype new password must not be blank",
                                trigger: "blur",
                            },
                        ],
                    },
                },
            }
        },
        methods: {
            submitChangePassword(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.showLoading();
                        authApi
                            .changePassword(this.changePasswordModel.changePasswordForm)
                            .then((response_data) => {
                                if (response_data.data && response_data.data.result === 0) {
                                    this.showSuccessToast("Your password has been changed !");
                                    this.changePasswordModel.changePasswordForm = {
                                        oldPassword: "",
                                        newPassword: "",
                                        confirmPassword: "",
                                    };
                                    this.$emit('changePasswordCompleted');
                                } else {
                                    if (
                                        Array.isArray(response_data.data.data) &&
                                        response_data.data.data.length > 0
                                    ) {
                                        commonAct.showError(response_data.data.data[0]);
                                        return;
                                    }
                                    if (
                                        response_data.data &&
                                        response_data.data.message !== null &&
                                        response_data.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response_data.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                                this.hideLoading();
                            })
                            .catch((error) => {
                                console.error(error);
                                this.hideLoading();
                                commonAct.showError(error);
                            });
                    } else {
                        this.hideLoading();
                        return false;
                    }
                });
            },
        }
    }
</script>